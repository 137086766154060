<template>
	<HeaderBar
		service-title="Mon test IST"
		:navigation-items="navigationItems"
	>
		<template #brand-content>
			<h1 class="text-caption text-md-h6 font-weight-medium">
				Mon test IST
			</h1>
		</template>
	</HeaderBar>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { NavigationItem } from '@cnamts/vue-dot/src/patterns/HeaderBar/types';

	@Component({
		components: {}
	})
	export default class AppHeader extends Vue {
		get navigationItems(): NavigationItem[] | undefined {
			if (this.$maintenanceEnabled) {
				return undefined;
			}

			return [
				{
					label: 'Accueil',
					to: {
						name: 'home'
					}
				},
				{
					label: 'Commandez un kit',
					to: {
						name: 'informationsSanitaires'
					}
				},
				{
					label: 'Suivi de commande',
					to: {
						name: 'tracking'
					}
				},
				{
					label: 'Mode d\'emploi du test',
					to: {
						name: 'manual'
					}
				},
				{
					label: 'Questions fréquentes',
					to: {
						name: 'faq'
					}
				}
			];
		}
	}
</script>

<style lang="scss" scoped>
	.vd-header-bar-container :deep(.vd-divider) {
		fill: #49A660;
	}
	.text-caption {
		color: #49A660;
	}
</style>
