// Disable return types for import functions
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Directly import Home view for faster rendering of first page
import Home from '@/views/Home.vue';

/** See https://router.vuejs.org/fr/essentials/navigation.html for help */
export const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/manual',
		name: 'manual',
		// Route level code-splitting
		// This generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited
		component: () => import(
			/* webpackChunkName: "about" */
			'@/views/Manual.vue'
		)
	},
	{
		path: '/maintenance',
		name: 'maintenance',
		component: () => import(
			/* webpackChunkName: "maintenance" */
			'@/views/Maintenance.vue'
		)
	},
	{
		path: '*', // Everything that doesn't match a route
		name: 'notFound',
		component: () => import(
			/* webpackChunkName: "not-found" */
			'@/views/NotFound.vue'
		)
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import(
			/* webpackChunkName: "faq" */
			'@/views/Faq.vue'
		)
	},
	{
		path: '/tracking',
		name: 'tracking',
		component: () => import(
			/* webpackChunkName: "faq" */
			'@/views/ConnexionSuiviCommande.vue'
		)
	},
	{
		path: '/trackingResult',
		name: 'trackingResult',
		component: () => import(
			/* webpackChunkName: "faq" */
			'@/views/SuiviCommandeResultat.vue'
			)
	},
	{
		path: '/signaler',
		name: 'signaler',
		component: () => import(
			/* webpackChunkName: "signaler" */
			'@/views/Signaler.vue'
		)
	},
	{
		path: '/consult',
		name: 'consult',
		component: () => import(
			/* webpackChunkName: "consult" */
			'@/views/Consult.vue'
		)
	},
	{
		path: '/preambule',
		name: 'preambule',
		component: () => import(
			/* webpackChunkName: "preambule" */
			'@/views/Preambule.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/eligibility',
		name: 'eligibility',
		component: () => import(
			/* webpackChunkName: "eligibility" */
			'@/views/Eligibility.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/question/:id([1-9]|1[012])',
		name: 'question',
		component: () => import(
			/* webpackChunkName: "questionnaire" */
			'@/views/Questionnaire.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/ineligibility',
		name: 'ineligibility',
		component: () => import(
			/* webpackChunkName: "ineligibility" */
			'@/views/Ineligibility.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/ineligibilityhomme',
		name: 'ineligibilityhomme',
		component: () => import(
			/* webpackChunkName: "ineligibilityhomme" */
			'@/views/IneligibilityHomme.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/typekit',
		name: 'typekit',
		component: () => import(
			/* webpackChunkName: "typekit" */
			'@/views/TypeKit.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/beneficiaire:currentFormStep?',
		name: 'beneficiaire',
		component: () => import(
			/* webpackChunkName: "beneficiaire." */
			'@/views/Beneficiaire.vue'
		),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/smsValidation',
		name: 'smsValidation',
		component: () => import(
			/* webpackChunkName: "smsValidation." */
			'@/views/SmsValidation.vue'
		)
	},
	{
		path: '/verificationBeneficiaire',
		name: 'verificationBeneficiaire',
		component: () => import(
			/* webpackChunkName: "VerificationBeneficiaire" */
			'@/views/VerificationBeneficiaire.vue'
			),
		meta: {
			ThresholdCheck : true
		}
	},
	{
		path: '/validation',
		name: 'validation',
		component: () => import(
			/* webpackChunkName: "validation." */
			'@/views/Validation.vue'
		)
	},
	{
		path: '/verificationLivraison',
		name: 'verificationLivraison',
		component: () => import(
			/* webpackChunkName: "verificationLivraison." */
			'@/views/VerificationLivraison.vue'
		)
	},
	{
		path: '/moyensContact',
		name: 'moyensContact',
		component: () => import(
			/* webpackChunkName: "moyensContact." */
			'@/views/MoyensContact.vue'
		)
	},
	{
		path: '/indisponible:serverError?',
		name: 'indisponible',
		component: () => import(
			/* webpackChunkName: "indisponible." */
			'@/views/Indisponible.vue'
		)
	},
	{
		path: '/recuperationNumeroCommande',
		name: 'recuperationNumeroCommande',
		component: () => import(
			/* webpackChunkName: "recuperationNumeroCommande." */
			'@/views/RecuperationNumeroCommande.vue'
		)
	},
	{
		path: '/accessibilite',
		name: 'a11yStatement',
		component: () => import(
			/* webpackChunkName: "DeclarationAccessibilite." */
			'@/views/DeclarationAccessibilite.vue'
		)
	},
	{
		path: '/cgu',
		name: 'cgu',
		component: () => import(
			'@/views/Cgu.vue'
		)
	},
	{
		path: '/mentions-legales',
		name: 'legalNotice',
		component: () => import(
			'@/views/LegalNotice.vue'
		)
	},
	{
		path: '/informations-sanitaires',
		name: 'informationsSanitaires',
		component: () => import(
			'@/views/informationsSanitaires.vue'
			)
	}
];
