<template>
	<div class="tutorial">
		<PageContainer size="xl">
			<CTypography
				tag="h2"
				class="tutorial__title"
			>
				Commandez votre kit de dépistage par auto-prélèvement
			</CTypography>

			<div class="tutorial__cards">
				<VCard class="pa-10 rounded-xl tutorial__card">
					<AppImage
						class="tutorial__card__img"
						:image="imageStepUrl.coordImage"
					/>
					<CTypography class="tutorial__card__title">
						En 5 minutes, je remplis les informations demandées
					</CTypography>
				</VCard>
				<VCard class="pa-10 rounded-xl tutorial__card">
					<AppImage
						class="tutorial__card__img"
						:image="imageStepUrl.coordImage2"
					/>
					<CTypography class="tutorial__card__title">
						Je renseigne l’adresse à laquelle je souhaite recevoir mon kit
					</CTypography>
				</VCard>
				<VCard class="pa-10 rounded-xl tutorial__card">
					<AppImage
						class="tutorial__card__img"
						:image="imageStepUrl.coordImage3"
					/>
					<CTypography class="tutorial__card__title">
						Je vérifie mes informations et je valide ma commande
					</CTypography>
				</VCard>
			</div>

			<VCard class="rounded-xl tutorial__card-alert">
				<PageContainer
					size="m"
					class="tutorial__card-alert__content"
				>
					<AppImage
						class="tutorial__card-alert__img"
						:image="imagealertUrl.coordImage"
					/>
					<CTypography
						class="tutorial__card-alert__title"
						tag="h2"
					>
						Votre kit de dépistage vous sera livré à l'adresse renseignée dans une enveloppe neutre, sous 7 jours hors week-end et jours fériés.<br>
						Vous allez recevoir un message de confirmation par mail et par SMS.
					</CTypography>
					<VBtn
						class="text-center theme--light my-5 px-10 py-5"
						color="primary"
						@click="goToForm()"
					>
						Commandez votre kit
					</VBtn>
					<CTypography class="tutorial__card-alert__info">
						La commande d’un kit de dépistage ne vous intéresse pas ou vous avez plus de 25 ans ?
					</CTypography>
					<a
						href="https://www.sante.fr/recherche/trouver/laboratoires"
						target="_blank"
						class="tutorial__card-alert__link"
					>
						<CTypography>Consultez les différents lieux de dépistage</CTypography>
					</a>
				</PageContainer>
			</VCard>
		</PageContainer>
	</div>
</template>

<script lang="ts">
	import S_Tutorial from './S_Tutorial';
	export default S_Tutorial;
</script>

<style src="./S_Tutorial.scss" lang="scss"></style>
