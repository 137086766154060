import Vue from 'vue';
import Component from 'vue-class-component';
import { IMAGES } from '@/constants/image';
import CTypography from '@/components/home/component/CTypography.vue';
import AppImage from '@/components/image/image.vue';
import { ImageUrl } from '@/types';

@Component({
	components: { AppImage, CTypography }
})
export default class S_Information extends Vue {
	get imageUrl(): ImageUrl {
		return {
			coordImage: IMAGES.CHLAMYDIAE,
			coordImage2: IMAGES.GONOCOQUE
		};
	}

	get liens(): object {
		return {
			lienChlamydiaEnSavoirPlus: 'https://www.ameli.fr/assure/sante/themes/infection-chlamydia/comprendre-infection',
			lienGonocoqueEnSavoirPlus: 'https://www.ameli.fr/assure/sante/themes/mst-ist/maladies-infections-sexuellement-transmissibles'
		};
	}

	goToForm(): void {
		this.$router.push({ name: 'informationsSanitaires' });
	}
}
