<template>
	<component
		:is="tag"
		:class="[
			`typography--${tag}`,
			'typography'
		]"
	>
		<slot />
	</component>
</template>

<script lang="ts">
	// 📦 Import Vue modules and decorators
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop } from 'vue-property-decorator';

	@Component
	export default class CTypographie extends Vue {
		@Prop({ default: 'p' })
		readonly tag!: string;
	}
</script>

<style lang="scss" scoped>
@use '@/theme/breakpoints.scss' as breakpoints;
@use '@/theme/fonts.scss';

.typography {
	font-family: "Source Sans 3", sans-serif;
	margin-bottom: 0;
	margin-top: 0;
}
.typography--h1 {
	font-weight: 700;
	font-size: 2.125rem;

	@media (max-width: breakpoints.$sm) {
		font-size: 1.5rem;
	}
}

.typography--h2 {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.95rem;

	@media (max-width: breakpoints.$sm) {
		font-size: 1.25rem;
	}
}

.typography--h4 {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 1.602rem;
}

.typography--p {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.424rem;
}
</style>
