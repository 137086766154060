<template>
	<img
		class="image"
		:alt="image"
		:src="source"
	>
</template>

<script lang="ts">
	import { getImagePath } from '@/services/image';
	import { Component, Prop, Vue } from 'vue-property-decorator';

	@Component({
		name: 'appImage'
	})
	export default class AppImage extends Vue {
		@Prop()
		readonly image!: string;

		get source(): string {
			return getImagePath(this.image);
		}
	}
</script>

<style lang="scss" scoped>
.image {
	width: 100%;
	height: 100%;
}
</style>

