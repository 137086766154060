<template>
	<transition :name="transitionName">
		<VCard
			v-show="show"
			class="pa-4 i-card"
		>
			<slot />
		</VCard>
	</transition>
</template>

<script lang="ts">
	// 📦 Import Vue modules and decorators
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop } from 'vue-property-decorator';

	@Component
	export default class ICard extends Vue {
		@Prop({ required: true })
		readonly transitionName!: string;
		@Prop({ required: true })
		readonly show!: string;
	}
</script>

<style scoped>
.i-card {
	opacity: 1;
	transform: translateY(0);
}

.i-card-one-enter-active {
	animation: fadeInDown 0.5s ease forwards;
}
.i-card-two-enter-active {
	animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
</style>
