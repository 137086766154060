<template>
	<PageContainer
		size="xl"
		class="notice"
	>
		<div class="notice__content">
			<div class="notice__cards">
				<div class="notice__card__explain">
					<CTypography
						tag="h2"
						class="notice__card__explain__title"
					>
						En savoir plus sur le fonctionnement du test
					</CTypography>
					<CTypography class="notice__card__explain__text">
						Simple et sans douleur, le dépistage se fait par auto-prélèvement vaginal chez les femmes et
						auto-prélèvement urinaire chez les hommes. Dépistées à temps, ces 2 IST se soignent grâce à un
						traitement antibiotique adapté.
					</CTypography>
					<VBtn
						class="text-center theme--light px-10 py-5"
						color="primary"
						@click="goToManual()"
					>
						Consultez les modes d'emploi
					</VBtn>
				</div>

				<VCard class="pa-10 rounded-xl notice__card">
					<div>
						<div class="notice__card__title">
							<CTypography tag="h2">
								Votre test est négatif <span class="notice__card__title__img" />
							</CTypography>
						</div>
						<CTypography class="notice__card__ask">
							Votre test ne révèle pas d’infection à Chlamydia et gonocoque.
						</CTypography>
						<CTypography class="notice__card__answer">
							Nous vous recommandons de vous tester à nouveau dans un an, ou plus tôt si vous avez un
							rapport sexuel non protégé avec un nouveau partenaire.
						</CTypography>
					</div>
					<div>
						<a
							:href="liens.lienConsulterProfessionnel"
							target="_blank"
							class="notice__card__link"
						>
							<CTypography>
								En cas  de symptômes, il est important de consulter  sans attendre un professionnel de
								santé.
							</CTypography>
						</a>
					</div>
				</VCard>
				<VCard class="pa-10 rounded-xl notice__card">
					<div>
						<div class="notice__card__title">
							<CTypography tag="h2">
								Votre test est positif <span class="notice__card__title__img" />
							</CTypography>
						</div>
						<CTypography class="notice__card__ask">
							Votre test révèle une infection à Chlamydia et/ou à gonocoque, consultez rapidement votre
							médecin traitant ou un autre médecin généraliste, une sage-femme ou un gynécologue si vous
							êtes une femme, pour obtenir un traitement adapté.
						</CTypography>
						<CTypography class="notice__card__answer">
							Il est important de prévenir votre ou vos partenaires sexuels afin qu’ils réalisent
							également le test. Ils peuvent eux aussi être infectés.
						</CTypography>
					</div>
					<div>
						<a
							:href="liens.lienTrouvezCentre"
							target="_blank"
							class="notice__card__link"
						>
							<CTypography>
								Retrouvez différents lieux pour vous faire traiter
							</CTypography>
						</a>
					</div>
				</VCard>
			</div>
		</div>
	</PageContainer>
</template>

<script lang="ts">
	import S_Notice from './S_Notice';
	export default S_Notice;
</script>

<style src="./S_Notice.scss" lang="scss"></style>
