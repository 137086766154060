<template>
	<div class="information">
		<PageContainer size="xl">
			<div class="information__header">
				<!-- TODO: AlertWrapper -->
				<div class="information__labels">
					<div class="information__labels__blue">
						<CTypography tag="h2">
							Sans ordonnance
						</CTypography>
					</div>
					<div class="information__labels__red">
						<CTypography tag="h2">
							Sans frais
						</CTypography>
					</div>
				</div>
				<CTypography
					tag="h1"
					class="information__title"
				>
					Le test à domicile vous permettra de détecter <span class="information__highlight">deux IST très répandues</span>
				</CTypography>
			</div>
			<div class="information__content">
				<div class="information__content__cards">
					<VCard class="pa-10 rounded-xl">
						<div class="information__content__spacer">
							<div>
								<div class="information__content__header">
									<CTypography
										class="information__content__header__title t-pink"
										tag="h2"
									>
										<AppImage
											class="information__content__header__img i-pink"
											:image="imageUrl.coordImage"
										/>
										L’infection à Chlamydia
									</CTypography>
								</div>
								<CTypography class="information__content__text">
									L’infection à Chlamydia, également appelée chlamydiose, est l’une des IST les plus fréquentes chez
									les jeunes, notamment chez les femmes. Si elle n’est pas traitée, elle peut provoquer des
									complications. C’est l’une des premières causes de stérilité. Les hommes sont également touchés et
									peuvent transmettre la Chlamydia à leur partenaire sans le savoir. Pourtant, l’infection à Chlamydia
									se soigne facilement avec des antibiotiques !
								</CTypography>
							</div>
							<a
								:href="liens.lienChlamydiaEnSavoirPlus"
								target="_blank"
								class="information__content__link"
							>
								<CTypography>Tout savoir sur l’infection à Chlamydia</CTypography>
							</a>
						</div>
					</VCard>
					<VCard class="pa-10 rounded-xl">
						<div class="information__content__spacer">
							<div>
								<div class="information__content__header">
									<CTypography
										class="information__content__header__title t-green"
										tag="h2"
									>
										<AppImage
											class="information__content__header__img i-green"
											:image="imageUrl.coordImage2"
										/>
										L’infection à gonocoque
									</CTypography>
								</div>
								<CTypography class="information__content__text">
									L’infection à gonocoque, également appelée gonorrhée, est en augmentation, surtout chez les hommes.
									Elle peut provoquer des douleurs de type brûlures (en urinant) et des écoulements génitaux (de la
									verge chez l’homme ou du vagin chez la femme). 70% des cas sont sans symptômes chez les femmes qui
									peuvent transmettre l’infection à leur partenaire. Si l’infection n’est pas traitée, elle peut
									entraîner des complications importantes. Pourtant, l’infection à gonocoque se soigne avec des
									antibiotiques !
								</CTypography>
							</div>
							<a
								:href="liens.lienGonocoqueEnSavoirPlus"
								target="_blank"
								class="information__content__link"
							>
								<CTypography>Tout savoir sur l’infection à gonocoque</CTypography>
							</a>
						</div>
					</VCard>
				</div>
				<div class="d-flex justify-center my-15">
					<VBtn
						class="text-center theme--light px-10 py-5"
						color="primary"
						@click="goToForm()"
					>
						Commandez votre kit
					</VBtn>
				</div>
			</div>
		</PageContainer>
	</div>
</template>

<script lang="ts">
	import S_Information from './S_Information';
	export default S_Information;
</script>

<style src="./S_Information.scss" lang="scss"></style>
