// 📦 Importations des modules Vue et décorateurs des icônes
import Vue from 'vue';
import Component from 'vue-class-component';
import { mdiHome, mdiFlaskEmpty, mdiInformationOutline } from '@mdi/js';
// 🛠️ Types et constantes
import { ImageUrl } from '@/types';
import { IMAGES } from '@/constants/image';
// 📌 Importation des composants enfants
import CTypography from '@/components/home/component/CTypography.vue';
import AppImage from '@/components/image/image.vue';
import CBadge from '@/components/home/component/CBadge.vue';
import ICard from '@/components/home/component/ICard.vue';

@Component({
	components: {
		ICard,
		CBadge,
		AppImage,
		CTypography
	}
})
export default class S_Header extends Vue {
	icons = {
		home: mdiHome,
		flaskEmpty: mdiFlaskEmpty,
		informationOutline: mdiInformationOutline
	};

	showCards = false;
	private readonly VIDEO_ID: string = '1026957104';
	isCookieVimeo = true; // Feature sur la gestion cookies vimeo

	mounted() {
		setTimeout(() => {
			this.showCards = true;
		}, 50);
	}

	/**
	 * 🔗 Génération de l'URL de la vidéo
	 * @returns Lien de la vidéo Vimeo
	 */
	videoUrl(): string {
		return `https://player.vimeo.com/video/${this.VIDEO_ID}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
	}

	/**
	 * 🖼️ Récupération de l'URL de l'image
	 * @returns URL de l'image de coordImage
	 */
	get imageUrl(): ImageUrl {
		return {
			coordImage: IMAGES.LOGO_IST
		};
	}

	handleActiveCookiesVimeo(): void {
		// TODO: On active les cookies
	}
}
