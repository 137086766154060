<template>
	<div class="faq">
		<AppImage
			class="faq__img"
			:image="imageUrl.coordImage"
		/>
		<PageContainer
			size="l"
			class="faq__content"
		>
			<CTypography class="faq__content__title">
				Questions fréquentes
			</CTypography>
			<VBtn
				class="text-center theme--light px-10 py-5"
				color="primary"
				@click="goToFAQ()"
			>
				Voir toutes les questions fréquentes
			</VBtn>
			<AppImage
				class="faq__img-mobile"
				:image="imageUrl.coordImage"
			/>
		</PageContainer>
	</div>
</template>

<script lang="ts">
	import S_Faq from './S_Faq';
	export default S_Faq;
</script>

<style src="./S_Faq.scss" lang="scss"></style>
