import Component from 'vue-class-component';
import Vue from 'vue';
import CTypography from '@/components/home/component/CTypography.vue';
import { LINK_QUESTION_SEXUALITE, LINK_SIDA_INFO_SERVICE } from '@/constants';

@Component({
	components: { CTypography }
})
export default class Notice extends Vue{
	goToManual(): void {
		this.$router.push({ name: 'manual' });
	}

	get liens(): object {
		return {
			lienConsulterProfessionnel: `${LINK_QUESTION_SEXUALITE}trouver-un-professionnel`,
			lienTrouvezCentre: `${LINK_SIDA_INFO_SERVICE}annuaire/`
		};
	}
}
