import Component from 'vue-class-component';
import Vue from 'vue';
import CTypography from '@/components/home/component/CTypography.vue';
import AppImage from '@/components/image/image.vue';
import { IMAGES } from '@/constants/image';

@Component({
	components: { AppImage, CTypography }
})
export default class Labo extends Vue {
	goToForm(): void {
		window.open('https://www.sante.fr/recherche/trouver/laboratoires', '_blank');
	}

	goToAmeli(): void {
		window.open('https://www.ameli.fr/assure/sante/themes/mst-ist', '_blank');
	}

	get imageUrl() : object {
		return {
			arrowUpImage: IMAGES.ARROWUP
		};
	}
}
