<template>
	<div class="badge">
		<slot />
	</div>
</template>

<script lang="ts">
	// 📦 Import Vue modules and decorators
	import { Vue } from 'vue-property-decorator';
	import { Component } from 'vue-property-decorator';

	@Component
	export default class CBadge extends Vue {}
</script>

<style lang="scss" scoped>
	@use '@/theme/colors.scss' as themeColors;
	.badge {
		background-color: #E7ECF5;
		padding: 5px 10px;
		border-radius: 90px!important;
		display: flex;
		align-items: center;
		gap: 10px;
		width: auto;
		max-width: fit-content;

		p {
			color: themeColors.$DARKBLUE;
			font-weight: 700;
		}
	}
</style>
