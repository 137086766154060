import Component from 'vue-class-component';
import Vue from 'vue';
import CTypography from '@/components/home/component/CTypography.vue';
import AppImage from '@/components/image/image.vue';
import { ImageUrl } from '@/types';
import { IMAGES } from '@/constants/image';

@Component({
	components: { AppImage, CTypography }
})
export default class S_Tutorial extends Vue {
	get imageStepUrl(): ImageUrl {
		return {
			coordImage: IMAGES.STEP1,
			coordImage2: IMAGES.STEP2,
			coordImage3: IMAGES.STEP3
		};
	}
	get imagealertUrl(): ImageUrl {
		return {
			coordImage: IMAGES.ALERT_ENVELOPPE
		};
	}

	goToForm(): void {
		this.$router.push({ name: 'informationsSanitaires' });
	}
}
