<template>
	<div class="float-container">
		<RoundedButton
			v-if="selectedOption === 'kit'"
			class="float-button"
			:text-color="textColor"
			:background-color="backgroundColor"
			:route="'/informations-sanitaires'"
		>
			{{ text }}
		</RoundedButton>
		<a
			data-cy="arrow-up-link"
			class="arrow-up-link"
			href="#go-top-page"
		>
			<RoundedButton
				class="float-button-top"
				:text-color="themeColors.DARKBLUE"
				:background-color="themeColors.WHITE"
				:style="{ width: '50px', padding: '0 !important' }"
				bordered
			>
				<AppImage
					class="arrow-up"
					:image="imageUrl.arrowUpImage"
				/>
			</RoundedButton>
		</a>
	</div>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { themeColors } from '@/theme/colors';
	import AppImage from '@/components/image/image.vue';
	import RoundedButton from './roundedButton.vue';
	import { IMAGES } from '@/constants/image';

	@Component({
		components: {
			RoundedButton,
			AppImage
		}
	})
	export default class FloatButton extends Vue {
		@Prop()
		readonly textColor!: string;
		@Prop()
		readonly backgroundColor!: string;
		@Prop()
		readonly text!: string;
		@Prop()
		readonly selectedOption!: string;

		get imageUrl() {
			return {
				arrowUpImage: IMAGES.ARROWUP
			};
		}

		get style() {
			return {
				textColor :this.textColor,
				backgroundColor: this.backgroundColor
			};
		}

		get themeColors() {
			return themeColors;
		}
	}
</script>

<style lang="scss" scoped>
.float-container {
	z-index: 2;
	display: flex;
	width: 100vw;
	position: fixed;
	bottom: 16px;
	justify-content: end;

	.float-button {
		margin-right: 18px;

	}
	.arrow-up-link {
		display: contents;
	}
	.float-button-top {
		min-width: 0;
		width: 46px;
		padding: 0 12px;
	}
}
@media (min-width: 300px) {
	.float-button {
    margin-left: auto;
	}
	.float-button-top {
    margin-right: auto;
    margin-left: 0px;
	}
}
@media (min-width: 768px) {
	.float-container {
		bottom: 32px;
		right: 0;
	}
	.float-button-top {
		margin-right: 36px;
	}
}

</style>
