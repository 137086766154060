<template>
	<PageContainer
		size="l"
		class="condom"
	>
		<div class="condom__content">
			<div class="condom__illustration">
				<AppImage
					class="condom__img"
					:image="imageUrl.coordImage"
				/>
				<CTypography class="condom__subtitle">
					Préservatif
				</CTypography>
			</div>

			<CTypography
				tag="h2"
				class="condom__title"
			>
				Pour rappel, le préservatif est le moyen le plus efficace de se protéger contre les IST.
			</CTypography>
		</div>
	</PageContainer>
</template>

<script lang="ts">
	import S_Condom from './S_Condom';
	export default S_Condom;
</script>

<style src="./S_Condom.scss" lang="scss"></style>
