import Component from 'vue-class-component';
import Vue from 'vue';
import AppImage from '@/components/image/image.vue';
import { ImageUrl } from '@/types';
import { IMAGES } from '@/constants/image';
import CTypography from '@/components/home/component/CTypography.vue';

@Component({
	components: { CTypography, AppImage }
})
export default class S_Condom extends Vue {
	get imageUrl(): ImageUrl {
		return {
			coordImage: IMAGES.CONDOM
		};
	}
}
